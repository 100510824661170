import Backend from "../tools/Backend";
import {setFatalError} from "./FatalErrorModule";
import {vkConnectRequest} from "../tools/VkConnectRequest";
import VkSdk from "@happysanta/vk-apps-sdk";

const SET_LOADING = "Click.SET_LOADING";
const SET_LOADED = "Click.SET_LOADED";

const SET_NOTIFICATIONS_ENABLED = "Click.SET_NOTIFICATIONS_ENABLED";

const SET_USER_STATUS = "Click.SET_USER_STATUS";
const SET_USER_ALREADY_REFUSED = "Click.SET_USER_ALREADY_REFUSED";
const SET_USER_STATUS_UPDATING = "Click.SET_USER_STATUS_UPDATING";
const SET_USER_INFO = "Click.SET_USER_INFO";

const SET_BANNING_ACTIVE = "Click.SET_BANNING_ACTIVE";

const SET_FINAL_COUNT = "Click.SET_FINAL_COUNT";

const SET_STORY_UPLOADING = "Click.SET_STORY_UPLOADING";

export const CLICK_TIME = 1556103600;
export const FREEZE_TIME = 1800;
export const BANNING_DURATION = 900;
export let BAN_PER_SEC = 300;

const initState = {
	areNotificationsEnabled: VkSdk.getStartParams().areNotificationsEnabled,
	userAlreadyRefused: false,
	userInfo: {},
	loading: false,
	loaded: false,
	userStatus: 'unknown', // member, banned, alive
	survivorsCount: 0,
	disappearedCount: 0,
	banningIsActive: false
};

const ClickModule = (state = initState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return {...state, loading: action.loading};
		case SET_LOADED:
			return {...state, loaded: action.loaded};

		case SET_NOTIFICATIONS_ENABLED:
			return {...state, areNotificationsEnabled: action.areNotificationsEnabled};
		case SET_USER_STATUS:
			return {...state, userStatus: action.userStatus};
		case SET_USER_ALREADY_REFUSED:
			return {...state, userAlreadyRefused: action.userAlreadyRefused};
		case SET_USER_STATUS_UPDATING:
			return {...state, userStatusUpdating: action.userStatusUpdating};
		case SET_USER_INFO:
			return {...state, userInfo: action.userInfo};

		case SET_BANNING_ACTIVE:
			return {...state, banningIsActive: action.banningIsActive};

		case SET_FINAL_COUNT:
			return {...state, survivorsCount: action.survivorsCount, disappearedCount: action.disappearedCount};

		case SET_STORY_UPLOADING:
			return {...state, storyUploading: action.storyUploading};

		default:
			return state
	}
};

function setLoading(loading) {
	return { type: SET_LOADING, loading }
}
function setLoaded(loaded) {
	return { type: SET_LOADED, loaded }
}

export function setNotificationsEnabled(areNotificationsEnabled) {
	return { type: SET_NOTIFICATIONS_ENABLED, areNotificationsEnabled }
}
export function setUserStatus(userStatus) {
	return { type: SET_USER_STATUS, userStatus }
}
export function setUserAlreadyRefused(userAlreadyRefused) {
	return { type: SET_USER_ALREADY_REFUSED, userAlreadyRefused }
}
export function setUserStatusUpdating(userStatusUpdating) {
	return { type: SET_USER_STATUS_UPDATING, userStatusUpdating }
}
export function setUserInfo(userInfo) {
	return { type: SET_USER_INFO, userInfo }
}

export function setBanningIsActive(banningIsActive) {
	return { type: SET_BANNING_ACTIVE, banningIsActive }
}

export function setFinalCount(survivorsCount,disappearedCount) {
	return { type: SET_FINAL_COUNT, survivorsCount, disappearedCount }
}

export function setStoryUploading(storyUploading) {
	return { type: SET_STORY_UPLOADING, storyUploading }
}

export function setClickInfo() {
	return async (d, gs) => {
		let {loading, loaded} = gs().ClickModule;

		if (loading) return;

		d(setLoading(true));
		try {
			const dateNow = Math.round((new Date()) / 1000);

			if (!loaded) {
				vkConnectRequest('VKWebAppGetUserInfo', {}, 'VKWebAppGetUserInfoResult', 'VKWebAppGetUserInfoFailed')
					.then(res => d(setUserInfo(res)));
			}

			let status;

			if (dateNow < CLICK_TIME) {
				const result = await Backend.request('subscription/has');
				status = result === 1 ? 'member' : 'unknown';

				d(setUserAlreadyRefused(result === 0))
			} else {
				const result = await Backend.request('status');

				status = result.status === 1 ? 'alive' : result.status === 0 ? 'banned' : 'unknown';

				let {positive, negative} = result;
				if (status === 'alive') positive = positive > 0 ? positive - 1 : positive;
				if (status === 'banned') negative = negative > 0 ? negative - 1 : negative;

				BAN_PER_SEC = Math.round(negative/BANNING_DURATION);

				d(setBanningIsActive((dateNow - CLICK_TIME) < BANNING_DURATION));
				d(setFinalCount(positive, negative))
			}

			d(setUserStatus(status));

			d(setLoading(false));
			d(setLoaded(true));
		} catch (err) {
			d(setFatalError(err))
		}
	}
}

export function changeStatusInGame(status) {
	return async (d, gs) => {

		d(setUserStatusUpdating(true));
		try {
			if (status === 'member') {
				await Backend.request('subscription/subscribe', {}, "POST");
			} else {
				await Backend.request('subscription/unsubscribe', {}, "POST");
			}

			d(setUserStatus(status));
			d(setUserStatusUpdating(false));
		} catch (err) {
			d(setFatalError(err))
		}
	}
}

export function shareStoryUpload(url, status, sex) {
	return async (d, gs) => {
		d(setStoryUploading(true));
		try {
			const type = status === 'alive' ? 'positive' : status === 'banned' ? 'negative' : '';

			await Backend.request('sharing/stories', {url, type, sex}, "POST");

			d(setStoryUploading(false));
		} catch (err) {
			d(setFatalError(err))
		}
	}
}

export default ClickModule
