export default function ru() {
	return {
		translation: {
			oops: 'Упс, что-то\nсломалось',
			cancel: 'Отмена',
			error_details: 'Подробнее об ошибке',
			network: 'Проверьте соединение с интернетом',
			error: 'Ошибка',
			close: 'Закрыть',
			not_supported: 'Упс, приложение не поддерживает Вашу операционную систему',

			app_title: 'Щелчок Таноса',

			button_participate: 'Участвовать',
			button_accept: 'Подтвердить участие',
			button_refuse_small: 'Отказаться',
			button_refuse: 'Отказаться от участия',
			button_watch_translation: 'Смотреть трансляцию',
			button_share: 'Поделиться',
			button_post: 'Опубликовать на стене',
			button_post_desktop: 'Поделиться на стене',
			button_story: 'Добавить в историю',
			button_cancel: 'Отменить',
			button_story_publish_small: 'Опубликовать',
			button_story_publish: 'Опубликовать историю',

			start_screen_title: 'Щелчок Таноса',
			start_screen_title_mobile: 'Щелчок &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Таноса',
			start_screen_text_refused: 'Ваша страница не&nbsp;пострадает после щелчка Таноса. Если Вы всё-таки решите испытать судьбу — нажмите кнопку «Участвовать». Передумать можно до&nbsp;24&nbsp;апреля&nbsp;13:30 по&nbsp;московскому времени.',
			start_screen_text_1: 'Год назад титан Танос из&nbsp;блокбастера «Мстители: Война бесконечности» одним щелчком пальцев уничтожил половину всех существ во&nbsp;Вселенной.',
			start_screen_text_2: '24 апреля, в день мировой премьеры фильма «Мстители: Финал», мы&nbsp;повторим поступок Таноса и&nbsp;на&nbsp;время заблокируем половину пользователей ВКонтакте, которые не&nbsp;побоятся принять участие в&nbsp;акции. ',
			start_screen_text_3: 'Пошёл финальный раунд! Вы с&nbsp;нами?',

			terms_screen_title: 'Обратите внимание',
			terms_screen_text_1: 'Акция начнётся 24&nbsp;апреля\nв&nbsp;14:00 по&nbsp;московскому времени.',
			terms_screen_text_2: 'Если последствия щелчка Таноса коснутся Вашей страницы, она будет заморожена на&nbsp;15&nbsp;минут. ',

			counter_screen_days_0: '{{count}}&nbsp;день',
			counter_screen_days_1: '{{count}}&nbsp;дня',
			counter_screen_days_2: '{{count}}&nbsp;дней',
			counter_screen_hours_0: '{{count}}&nbsp;час',
			counter_screen_hours_1: '{{count}}&nbsp;часа',
			counter_screen_hours_2: '{{count}}&nbsp;часов',
			counter_screen_minutes_0: '{{count}}&nbsp;минута',
			counter_screen_minutes_1: '{{count}}&nbsp;минуты',
			counter_screen_minutes_2: '{{count}}&nbsp;минут',
			counter_screen_text_0: 'осталась до щелчка Таноса',
			counter_screen_text_1: 'осталось до щелчка Таноса',
			counter_screen_text_2: 'осталось до щелчка Таноса',

			final_screen_disappeared: 'рассыпались в&nbsp;прах',
			final_screen_disappeared_you: 'Вы и ещё',
			final_screen_disappeared_0: 'рассыпались в&nbsp;прах',
			final_screen_disappeared_1: 'рассыпались в&nbsp;прах',
			final_screen_disappeared_2: 'рассыпались в&nbsp;прах',
			final_screen_survivors: 'остались в&nbsp;живых',
			final_screen_survivors_you: 'Вы и ещё',
			final_screen_survivors_0: 'остались в&nbsp;живых',
			final_screen_survivors_1: 'остались в&nbsp;живых',
			final_screen_survivors_2: 'остались в&nbsp;живых',

			sharing_text_member_f: 'Я решила испытать судьбу и присоединилась к акции «Щелчок Таноса».\n\n24 апреля от меня, быть может, останется только пыль… Готовы рискнуть вместе со мной?\n\n#ЩелчокТаноса',
			sharing_text_member_m: 'Я решил испытать судьбу и присоединился к акции «Щелчок Таноса».\n\n24 апреля от меня, быть может, останется только пыль… Готовы рискнуть вместе со мной?\n\n#ЩелчокТаноса',
			sharing_text_member_un: 'Я присоединяюсь к акции «Щелчок Таноса».\n\n24 апреля от меня, быть может, останется только пыль… Готовы рискнуть вместе со мной?\n\n#ЩелчокТаноса',
			sharing_text_alive_f: 'Я участвовала в акции «Щелчок Таноса» и осталась в живых!\n\nУдача на моей стороне.\n\n#ЩелчокТаноса',
			sharing_text_alive_m: 'Я участвовал в акции «Щелчок Таноса» и остался в живых!\n\nУдача на моей стороне.\n\n#ЩелчокТаноса',
			sharing_text_alive_un: '«Щелчок Таноса» обошёл меня стороной!\n\nУдача на моей стороне.\n\n#ЩелчокТаноса',
			sharing_text_banned_f: 'Я участвовала в акции «Щелчок Таноса» и обратилась в пыль.\n\nТолько представьте — пришлось провести 15 минут без ВКонтакте!\n\n#ЩелчокТаноса',
			sharing_text_banned_m: 'Я участвовал в акции «Щелчок Таноса» и обратился в пыль.\n\nТолько представьте — пришлось провести 15 минут без ВКонтакте!\n\n#ЩелчокТаноса',
			sharing_text_banned_un: '«Щелчок Таноса» обратил мою страницу в пыль.\n\nТолько представьте — пришлось провести 15 минут без ВКонтакте!\n\n#ЩелчокТаноса',

			story_sharing_success: 'История опубликована'
		}
	}
}
