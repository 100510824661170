import React, {Component} from "react"
import {connect} from "react-redux"
import "./FinalScreen.css"
import L from "../../lang/L"
import {nToBr} from "../../tools/helpers";
import VkSdk from "@happysanta/vk-apps-sdk";
import Button from "../Button/Button"
import {BAN_PER_SEC, BANNING_DURATION, CLICK_TIME, setBanningIsActive} from "../../modules/ClickModule";
import {vkConnectRequest} from "../../tools/VkConnectRequest";
import * as VkConnect from "@vkontakte/vkui-connect/index";

class FinalScreen extends Component {

	state = {
		disappearedCount: 0,
		survivorsCount: this.props.disappearedCount + this.props.survivorsCount,
		increaseInStep: BAN_PER_SEC
	};

	constructor(props) {
		super(props);

		this.setCounter = this.setCounter.bind(this);
		this.sharePost = this.sharePost.bind(this);
	}

	componentWillMount() {
		if (!this.props.banningIsActive) {
			this.setState({
				disappearedCount: this.props.disappearedCount,
				survivorsCount: this.props.survivorsCount,
			})
		} else {
			const secPassed = Math.round((new Date())/1000) - CLICK_TIME;

			this.setState({
				disappearedCount: secPassed * BAN_PER_SEC,
				survivorsCount: (this.props.disappearedCount + this.props.survivorsCount) - (secPassed * BAN_PER_SEC)
			});

			this.setCounter('disappeared', BAN_PER_SEC);
			this.setCounter('survivors', BAN_PER_SEC);
		}
	}

	componentWillUnmount() {
		clearInterval(this.disappearedCounter);
		clearInterval(this.survivorsCounter)
	}

	formatCount(count) {
		return nToBr(String(count).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1&nbsp;'));
	}

	setCounter(type, increasePerSec) {
		const min = increasePerSec - 10 < 0 ? increasePerSec : increasePerSec - 10,
			max = increasePerSec + 10;


		this[`${type}Counter`] = setInterval(() => {
			const dateNow = Math.round((new Date())/1000),
				banningIsActive = (dateNow - CLICK_TIME) < BANNING_DURATION,
				finalCount = this.props[`${type}Count`],
				curCount = this.state[`${type}Count`],
				changedAmount = type === 'survivors' ? this.state.increaseInStep : this.getRand(min, max);

			if (type === 'survivors' && curCount > finalCount && banningIsActive) {
				const newCount = curCount - changedAmount;
				this.setState({[`${type}Count`]: newCount > finalCount ? newCount : finalCount})
			} else if (type === 'disappeared' && curCount < finalCount && banningIsActive) {
				const newCount = curCount + changedAmount;
				this.setState({[`${type}Count`]: newCount < finalCount ? newCount : finalCount, increaseInStep: changedAmount})
			} else {
				this.props.setBanningIsActive(false);
				clearInterval(this[`survivorsCounter`]);
				clearInterval(this[`disappearedCounter`]);
				this.setState({survivorsCount: this.props.survivorsCount, disappearedCount: this.props.disappearedCount})
			}
		}, 1000)
	}

	getRand(min, max) {
		let rand = min + Math.random() * (max + 1 - min);
		return Math.floor(rand);
	}

	sharePost() {
		const {userStatus, userInfo} = this.props,
			sex = userInfo.sex === 1 ? 'f' : userInfo.sex === 2 ? 'm' : 'un';

		let snippetImage = window.location.origin + window.location.pathname;

		if (userStatus === 'member') {
			snippetImage = snippetImage + require(`../SharingMenu/img/snippet_member.png`);
		} else {
			snippetImage = snippetImage + require(`../SharingMenu/img/snippet_${userStatus}_${sex}.png`)
		}
		vkConnectRequest('VKWebAppShowWallPostBox',
			{
				message: L.t(`sharing_text_${userStatus}_${sex}`),
				attachments: `https://vk.com/app${VkSdk.getStartParams().appId}`,
				link_image: snippetImage,
				link_title: L.t('app_title'),
				link_button: 'open_url'
			},
			'VKWebAppShowWallPostBox', 'VKWebAppShowWallPostBoxFailed'
		)
			.then(() => { VkConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#25274A"}) })
			.catch(() => { VkConnect.send("VKWebAppSetViewSettings", {"status_bar_style": "light", "action_bar_color": "#25274A"}) } )
	}

	renderCount(type) {
		return (
			<div className="FinalScreen__part_inner">
				<div className="FinalScreen__count">{this.formatCount(this.state[`${type}Count`])}</div>
				<div className="FinalScreen__text">{nToBr(L.t(`final_screen_${type}`))}</div>
			</div>
		)
	}

	renderCountWithUser(type) {
		return (
			<div className="FinalScreen__part_inner">
				<div className="FinalScreen__title">
					{L.t(`final_screen_${type}_you`)} {this.formatCount(this.state[`${type}Count`])} {nToBr(L.t(`final_screen_${type}`, {count: this.state[`${type}Count`]}))}
				</div>
				<div className="FinalScreen__button_wrap">
					<Button className="FinalScreen__button" type="color" onClick={this.sharePost}>{L.t('button_share')}</Button>
				</div>
			</div>
		)
	}

	render() {
		const {userStatus, banningIsActive} = this.props;

		return (
			<div className="FinalScreen">
				<div className="FinalScreen__inner">
					<div className="FinalScreen__part FinalScreen__disappeared">
						{userStatus === 'banned' && !banningIsActive ?
							this.renderCountWithUser('disappeared') : this.renderCount('disappeared')
						}
						<div className={`FinalScreen__middle${banningIsActive ? ' FinalScreen__middle_translation' : ''}`}>
							{banningIsActive &&
								<Button className="FinalScreen__button" type="color" href="https://vk.com/video-29534144_456244765" target="_blank"
												rel="noopener noreferrer">
									{L.t('button_watch_translation')}
								</Button>
							}
						</div>
					</div>
					<div className="FinalScreen__part FinalScreen__survivors">
						{userStatus === 'alive' && !banningIsActive ?
							this.renderCountWithUser('survivors') : this.renderCount('survivors')
						}
					</div>
				</div>
			</div>
		)
	}
}

function map(state) {
	return {
		userInfo: state.ClickModule.userInfo,
		userStatus: state.ClickModule.userStatus,
		disappearedCount: state.ClickModule.disappearedCount,
		survivorsCount: state.ClickModule.survivorsCount,
		banningIsActive: state.ClickModule.banningIsActive
	}
}

export default connect(map, {setBanningIsActive})(FinalScreen)
